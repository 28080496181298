input {
  font-size: 16px;
  width: 100%;
  border-width: 0;
  border: none;
  background-color: transparent;
}

input:focus {
  outline: none !important;
}

.title {
  font-size: 18px;
  color: #a8a8a8;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.content {
  background: #f6f6f6;
  color: black;
  border-radius: 8px;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}

/* #container:focus-within #title {
  color: black !important;
  font-weight: 500;
}
 */

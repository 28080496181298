p {
  margin-top: 8px;
  margin-bottom: 8px;

}

.bubble {
  background-color: #99ff80;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
}
